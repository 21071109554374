<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="handleClose" class="nav__btn nav__btn--prev">
								<i class="icon icon-close">{{t('10658')}}</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10659')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="layer-coupon">
						<div class="box">
							<div class="booking-box">
								<div class="box box--reverse">
									<!-- 예약 숙소 1 -->
									<div class="booking-item" v-for="(orderCoupons, index) in ordersCoupons" :key="index">
										<div class="booking-item__badge">{{t('10087')}}</div>
										<h3 class="booking-item__title">{{orderCoupons.order.room.hotel_nm}}</h3>
										<ul class="coupon-status">
											<li v-if="orderCoupons.normalCoupon !== undefined">
												<span>{{t('10644')}}</span>
												<div class="flex">
													<span>{{orderCoupons.normalCoupon.rate !== 0 ? orderCoupons.normalCoupon.rate + "% 할인" : ""}}</span>
													<span>{{orderCoupons.normalCoupon.price.toLocaleString("en-US")}}원</span>
												</div>
											</li>
											<li v-if="orderCoupons.multipleCoupons.length > 0">
												<span>{{t('10648')}}</span>
												<div class="flex" v-for="(coupon, index) in orderCoupons.multipleCoupons" :key="index">
													<span>{{coupon.rate !== 0 ? coupon.rate + "% 할인" : ""}}</span>
													<span>{{coupon.price.toLocaleString("en-US")}}{{t('10088')}}</span>
												</div>
											</li>
										</ul>
									</div>
									<ul class="info">
										<li class="point" style="margin-top:24px;padding-top:24px;border-top-style:dashed">
											<h4 class="info__title">{{t('10660')}}</h4>
											<div class="info__text">
												<strong class="red">{{orders.reduce((acc, cur) => acc + cur.dcPrice, 0).toLocaleString("en-US")}}</strong>{{t('10088')}}
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n";
export default {
	props: ["handleClose", "selectedCoupons", "orders"],
	setup(props) {
		const ordersCoupons = computed(() => 
			Object.keys(props.selectedCoupons).map((key, index) => {
				const coupons = props.selectedCoupons[key];
				const normalCoupon = coupons.find(coupon => coupon.MULTIPLE_USE !== 'Y');
				const multipleCoupons = coupons.filter(coupon => coupon.MULTIPLE_USE === 'Y');
				const { t }= useI18n() 
				return {
					order: props.orders[index],
					normalCoupon: normalCoupon !== undefined ? {
						rate: normalCoupon.CP_DCRATE,
						price: normalCoupon.CP_AMT !== 0 ? normalCoupon.CP_AMT : Math.round(props.orders[index].price * normalCoupon.CP_DCRATE / 100),
						t,  //번역필수 모듈
		  i18n, 
					}: undefined,
					multipleCoupons: multipleCoupons.map(coupon => ({
						rate: coupon.CP_DCRATE,
						price: coupon.CP_AMT !== 0 ? coupon.CP_AMT : Math.round(props.orders[index].price * coupon.CP_DCRATE / 100),
					})),
				}
			})
		);

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})

		return {
			ordersCoupons,
		}
	}
}
</script>